import React from "react";
/*eslint-disable no-unused-vars*/
import { Carousel } from "bootstrap";
import Slide1 from "../assets/static/slide-1.jpg";
import Slide2 from "../assets/static/slide-2.jpg";
import Slide3 from "../assets/static/slide-3.jpg";

export default function Slides() {
  const imageUrl =
    "https://images.unsplash.com/photo-1432839318976-b5c5785ce43f?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1050&q=80";
  return (
    <div
      id="carouselExampleControls"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={Slide1} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item">
          <img src={Slide2} className="d-block w-100" alt="..." />
        </div>
        <div className="carousel-item">
          <img src={Slide3} className="d-block w-100" alt="..." />
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}
