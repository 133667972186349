import React from 'react';
import { FaFileDownload } from 'react-icons/fa';

export default function DetalleEscuelas(props) {
  return (
    <>
      <p>
        La escuela constituye un espacio propicio para abordar los hechos de la
        historia reciente, no sólo como vehículo de transferencia de
        información, sino como un espacio en el que se aprehendan las
        experiencias pasadas. Consideramos que el golpe de Estado de 1976 debe
        ser trabajado en las aulas para reflexionar sobre el valor de la
        democracia y la vigencia de los derechos humanos, para bucear en los
        conceptos básicos del Estado de Derecho y recuperar la importancia de la
        memoria construyendo un futuro donde hechos como estos no se repitan
        nunca más.
      </p>
      <p>
        El artículo 3º de la Ley de Educación Nacional N° 26.206, expresa que{' '}
        <em>
          “La educación es una prioridad nacional y se constituye en política de
          Estado para construir una sociedad justa, reafirmar la soberanía e
          identidad nacional, profundizar el ejercicio de la ciudadanía
          democrática, respetar los derechos humanos y libertades fundamentales
          y fortalecer el desarrollo económico-social de la Nación.”
        </em>{' '}
        Es en este sentido que construimos esta propuesta, puesto que pensamos
        que no se construye identidad nacional y no se fortalece la vida en
        democracia, si no se promueven los procesos de memoria, verdad y
        justicia.
      </p>
      <p>
        Esta propuesta radica en ofrecer una serie de materiales didácticos que
        promuevan la reflexión; enseñar los horrores de la sociedad es un tema
        complejo, pero ineludible para no repetir la ignominia del pasado. La
        perspectiva de la pedagogía de la Memoria no consiste solamente en
        detenerse en contenidos o efemérides, sino afrontar los hechos desde
        preguntas éticas, políticas, epistemológicas y afectivas.
      </p>
      <p>
        Esta oferta está orientada al trabajo con y entre los docentes, a los
        fines de su interpelación como trabajadores de la educación y como
        ciudadanos. Así las herramientas conceptuales y también históricas que
        encontrarán les permitirán acercarse a los hechos de manera más precisa.
      </p>
      <p>
        En estos días cercanos al 24, tendrán que investigar y trabajar desde
        distintos aspectos este momento de nuestra historia. Aquí seleccionamos
        materiales, muchos de ellos multimediales, que los acompañarán en la
        labor didáctica sobre la última dictadura militar.
      </p>
      <p>
        A esos materiales los hemos dividido en tres carpetas: Inicial,
        Primario, Secundario, para facilitarles la búsqueda. No obstante,
        algunos materiales de Inicial podrían servir para trabajar en Primer
        Ciclo de E.G.B. y viceversa.
      </p>
      <article>
        <h4>Nivel Inicial y Primer Ciclo de Primaria</h4>
        <p>
          En la carpeta de Inicial y Primer Ciclo de Nivel Primario,
          encontrarán: secuencias listas para usar; links de blogs que remiten a
          páginas en las que encontrarán distinto tipo de propuestas para
          trabajar el 24 de marzo; links de videos, canciones, películas y
          material de lectura elaborado por distintas instituciones y gremios.
        </p>
        <h5>Dossier</h5>
        <ul className='mb-4'>
          <li>
            Actividades para trabajar el 24 de marzo: cuentos, talleres, videos,
            canciones, frases, interrogantes que posibilitar&aacute;n abordar de
            diferentes maneras esta fecha hist&oacute;rica. Elaboradas por
            docentes integrantes de la Comisi&oacute;n de la Memoria de
            R&iacute;o Cuarto.
          </li>
          <li>Listado de canciones y videos alusivos.</li>
          <li>
            Las abuelas nos cuentan. Cuadernillo para docentes para el trabajo
            en el aula con alumnos de nivel inicial. Ministerio de
            Educaci&oacute;n de la Naci&oacute;n.
          </li>
          <li>
            Cuentos para los m&aacute;s chicos. Las abuelas nos cuentan.
            Ministerio de Educaci&oacute;n de la Naci&oacute;n.
          </li>
          <li>
            El reglamento es el reglamento. Adela Bach Las abuelas nos cuentan.
            Ministerio de Educaci&oacute;n de la Naci&oacute;n.
          </li>
          <li>
            24 de marzo. Las madres de plaza de mayo nos cuentan su historia y
            nos ense&ntilde;an su lucha. Propuestas para trabajar en el aula.
            Secretar&iacute;a de Derechos Humanos. SUTEBA. CTA. CTERA.
          </li>
          <li>
            El caso Gaspar. A 30 a&ntilde;os del golpe. Ministerio de
            Educaci&oacute;n de la Naci&oacute;n.
          </li>
          <li>Links de blogs para extraer ideas sobre la tem&aacute;tica</li>
          <li>Links de enlaces y materiales para trabajar el 24 de marzo.</li>
          <li>
            &iquest;Qu&eacute; se propuso destruir la dictadura? Material
            elaborado por SUTEBA y CTA. Bs. As.
          </li>
          <li>
            Serie Recursos para el aula. Ministerio de Educaci&oacute;n.
            Provincia de Chubut.
          </li>
          <li>
            Secuencia did&aacute;ctica elaborada por SUTEBA y CTA a 40
            a&ntilde;os del golpe.&nbsp;
          </li>
        </ul>
        <a
          href='https://drive.google.com/drive/folders/1-3X8B6xKe-nFR8KX3bn5_zPMxHFT70cQ?usp=sharing'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-primary text-white'
        >
          <FaFileDownload /> Descargar Material
        </a>
      </article>

      <article>
        <h4>Segundo ciclo de Primaria</h4>
        <p>
          Abordar la conmemoración del “Día Nacional por la Memoria, la Verdad y
          la Justicia” cada 24 de marzo representa un gran desafío. Es un día en
          el que debemos pensar y reflexionar sobre el valor de la democracia,
          los derechos, la identidad y la importancia de mantener viva la
          memoria para que, hechos como los sucedidos durante la última
          dictadura militar, no se repitan Nunca Más.
        </p>
        <p>
          Con el objetivo de analizar y recordar esta fecha histórica para los
          argentinos, proponemos las actividades detalladas a continuación:
        </p>
        <h5>Dossier</h5>
        <ul className='mb-4'>
          <li>Links para trabajar el 24 de marzo</li>
          <li>
            El 24 de marzo con los m&aacute;s peque&ntilde;os. Educ.ar.
            Ministerio de Educaci&oacute;n de la Naci&oacute;n.
          </li>
          <li>
            24 de marzo. Las madres de plaza de mayo nos cuentan su historia y
            nos ense&ntilde;an su lucha. Propuestas para trabajar en el aula.
            Secretar&iacute;a de Derechos Humanos. SUTEBA. CTA. CTERA.
          </li>
          <li>
            &iquest;Qu&eacute; se propuso destruir la dictadura? Material
            elaborado por SUTEBA y CTA. Bs. As.
          </li>
          <li>
            Secuencia did&aacute;ctica elaborada por SUTEBA y CTA a 40
            a&ntilde;os del golpe.&nbsp;
          </li>
          <li>
            Actividades para 5to y 6to grado elaboradas por docentes integrantes
            de la Comisi&oacute;n de la Memoria de R&iacute;o Cuarto.
          </li>
          <li>
            Proyectos pedag&oacute;gicos para trabajar el D&iacute;a de la
            Memoria con adaptaciones curriculares para personas con
            discapacidad. Elaborados por Marcela Bettiol, Adela Pag&eacute;s,
            Andrea Picco, M&oacute;nica Esquenazi y Julio L&oacute;pez.
          </li>
        </ul>

        <a
          href='https://drive.google.com/drive/folders/1hqCu2XuMT9qMIanOF-Iyz0MTK2XXNF3u?usp=sharing'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-primary text-white'
        >
          <FaFileDownload /> Descargar Material
        </a>
      </article>

      <article>
        <h4>Nivel Medio</h4>
        <p>
          Entre los diferentes materiales ofrecemos textos y audiovisuales para
          abordar en el aula cuestiones particulares y locales, como el referido
          a Clarisa Duarte hija de José Alfredo “Peco” Duarte, también un
          audiovisual sobre Raúl Augusto Bauducco y José Alberto Svagusa,
          detenidos desaparecidos de la ciudad de Río Cuarto y el alusivo a
          Antonia Cisneros, madre de Ignacio Manuel Cisneros también
          desaparecido durante la última dictadura militar. La elección de estos
          materiales no es azarosa, sino que manifiesta nuestra voluntad de
          expresar cercanía con quienes habiendo transitado las mismas calles de
          la ciudad que nosotros, fueron víctimas del terrorismo de Estado.
        </p>
        <p>
          La importancia de trabajar en las escuelas, en todos los niveles
          educativos, a través de nuestros jóvenes, nos da la posibilidad de
          fortalecer los valores democráticos y profundizar así la construcción
          de ciudadanos con conciencia social, críticos y con capacidad para
          decidir democráticamente.
        </p>
        <h5>Dossier</h5>
        <ul className='mb-4'>
          <li>
            Cartilla del 24 M &ndash; Instituto de Capacitaci&oacute;n e
            Investigaci&oacute;n de los Educadores de C&oacute;rdoba&nbsp;&nbsp;
          </li>
          <li>
            Audiovisuales Antonia Cisneros &ndash; IPEM 283 Fray Mamerto
            Esqui&uacute;&nbsp;
          </li>
          <li>
            Cartilla 42 a&ntilde;os del Golpe de Estado &ndash; Comisi&oacute;n
            Provincial por la Memoria&nbsp;
          </li>
          <li>Dictaduras Latinoamericanas &ndash; Canal Encuentro</li>
          <li>
            Clarisa &ndash; documental de estudiantes de la Carrera de
            Comunicaci&oacute;n Social de la Universidad Nacional de R&iacute;o
            Cuarto.
          </li>
          <li>
            Holocausto y Genocidio &ndash; Ministerio de Educaci&oacute;n de la
            Naci&oacute;n
          </li>
          <li>
            Murales por la Memoria - Ministerio de Educaci&oacute;n de la
            Naci&oacute;n. Curadur&iacute;a de Arte. Programa Educaci&oacute;n y
            Memoria. Espacio de Arte AMIA&nbsp;
          </li>
          <li>
            24 de Marzo Las Madres nos cuentan su historia y nos ense&ntilde;an
            su lucha.&nbsp;
          </li>
          <li>Porque recordamos el 24 de Marzo? &ndash; educ.ar</li>
          <li>
            A 40 a&ntilde;os del golpe genocida -&nbsp; SUTEBA &ndash;
            Secretar&iacute;a de DDHH
          </li>
          <li>
            Bauducco &ndash; Universidad Nacional de C&oacute;rdoba. - H.I.J.O.S
            &ndash; Familiares de detenidos y desaparecidos por razones
            pol&iacute;ticas de C&oacute;rdoba &ndash; Archivo provincial de la
            Memoria &ndash; Comisi&oacute;n UP 1
          </li>
          <li>
            Svagusa - Universidad Nacional de C&oacute;rdoba. - H.I.J.O.S
            &ndash; Familiares de detenidos y desaparecidos por razones
            pol&iacute;ticas de C&oacute;rdoba &ndash; Archivo provincial de la
            Memoria &ndash; Comisi&oacute;n UP 1
          </li>
        </ul>

        <a
          href='https://drive.google.com/drive/folders/146xdJuuKsx2jlcUJ0Q-WbTL_7CdZMyQh?usp=sharing'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-primary text-white'
        >
          <FaFileDownload /> Descargar Material
        </a>
      </article>
    </>
  );
}
