import React from 'react';
/*eslint-disable no-unused-vars*/
import { Collapse } from 'bootstrap';

export default function ComplementosBiblioteca() {
  return (
    <div className='complements'>
      <h4>Información Complementaria</h4>
      <h5>
        <a
          className=''
          data-bs-toggle='collapse'
          href='#jorgeHarriague'
          role='button'
          aria-expanded='false'
          aria-controls='jorgeHarriague'
        >
          ¿Quién fue Jorge Harriague?
        </a>
      </h5>
      <div className='collapse multi-collapse mb-3' id='jorgeHarriague'>
        <div className='card card-body'>
          <div className='row'>
            <div className='col-md-6'>
              <img
                className='img-fluid'
                loading='lazy'
                src='https://res.cloudinary.com/gobriocuarto/image/upload/v1615820058/CasaDeLaMemoria/Biblioteca/Recuadro_complementario_Harriague_fpdcdk.webp'
                alt=''
              />
            </div>
          </div>
          <p>
            Nació en Villa Mercedes, San Luis, el 20 de julio de 1950. En 1960
            se trasladó con su familia (sus padres y seis hermanos) a Río
            Cuarto, Córdoba. Teniendo un marcado perfil intelectual, siendo aún
            joven, se interesó, junto a otras personas, en fundar la Universidad
            de esa localidad cordobesa. El motivo no era solo la irradiación de
            un centro cultural, sino también la posibilidad de igualdad de
            condiciones para que estudien todos los que quisieran, y que fuera
            una palanca de desarrollo regional y al fin y al cabo una
            transformación social que modifique el esquema social de poder
            feudal existente en Río Cuarto hasta esos momentos. Jorge Rodolfo
            “León” Harriague (por su melena rubia) fue presidente del centro de
            estudiantes y militó en Montoneros.
          </p>
          <p>
            Fue secuestrado en Capital Federal el 1° de diciembre de 1977, a la
            edad de 27 años. Según testimonios de sobrevivientes estuvo en el
            CCD “Vesubio”. Su caso fue incluido en la causa judicial N° 1838
            “Vesubio II”. En diciembre del 2014 el Tribunal Oral en lo Criminal
            N° 4 de Capital Federal dictó sentencia por crímenes de lesa
            humanidad contra Jorge Raúl Crespi, Federico Antonio Minicucci,
            Gustavo Adolfo Cacivio, Néstor Norberto Cendón.
          </p>
          <p>
            Tiene también dos hermanos desaparecidos por la dictadura militar:
            Helena María, nacida en 1952 y ejecutada en La Plata en diciembre de
            1976 y Federico Juan que tenía 31 años al momento de su secuestro y
            posterior desaparición, en Capital Federal en junio de 1977. Helena
            y Federico comenzaron su militancia política y social trabajando en
            una parroquia de Río Cuarto.
          </p>
        </div>
      </div>
    </div>
  );
}
