import React from 'react';
import { Link } from 'react-router-dom';
import BannerMultimedia from '../assets/static/banner-aside.multimedia.gif';
// import BannerDesaparecidos from '../assets/static/banner-aside-desaparecidos-rio-cuarto.gif';
import BannerEscuelas from '../assets/static/banner-aside-recursos-para-trabajar-en-el-aula.gif';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

export default function Aside() {
  return (
    <aside>
      <Link className='card' to='/escuelas'>
        <img src={BannerEscuelas} alt='' className='img-fluid' />
      </Link>
      <Link className='card' to='/multimedia'>
        <img src={BannerMultimedia} alt='' className='img-fluid' />
      </Link>
      {/* <div className='card'>
        <img src={BannerDesaparecidos} alt='' className='img-fluid' />
      </div> */}
      <br />
      <hr />
      <h5>Seguinos en nuestras redes</h5>
      <div className='d-grid gap-2 mb-3'>
        <a
          href='https://www.facebook.com/casade.lamemoria.14'
          className='btn btn-outline-dark'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaFacebook /> Facebook
        </a>
        <a
          href='https://instagram.com/casadelamemoria.rio4?igshid=1fvrg2etbkeaa'
          className='btn btn-outline-dark'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaInstagram /> Instagram
        </a>
      </div>
    </aside>
  );
}
