import React from 'react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import LogoGRC from '../assets/static/logo-grc.svg';

export default function Footer() {
  return (
    <footer>
      <div className='container'>
        <div className='row'>
          <div className='col-md-4'>
            <div className='institutional'>
              Comisión Municipal <br /> de la Memoria
            </div>
          </div>
          <div className='col-md-4'>
            <div className='contact'>
              <div>
                <FaPhoneAlt /> 4671263
              </div>
              <div>
                <FaEnvelope /> casadelamemoria.riocuarto@gmail.com
              </div>
              <div>
                <FaMapMarkerAlt /> Bv. Ameghino y Príngles (Zona Andino)
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='brand-grc'>
              <img src={LogoGRC} alt='' />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
