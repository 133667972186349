import React from "react";
import Navbar from "../template/Navbar";
import Footer from "../template/Footer";

export default function Layout(props) {
  return (
    <>
      <Navbar />
      <main>{props.children}</main>
      <Footer />
    </>
  );
}
