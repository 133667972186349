import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import NewCard from '../components/NewCard';
import Slides from '../components/Slides';
import Aside from '../template/Aside';
// import data from '../data/posts.json';
import { usePosts } from '../hooks/usePosts';

export default function Home() {
  const { loading, posts } = usePosts({ limit: 2 });
  //console.log(data.posts);
  //const posts = data.posts;
  //console.log(posts);
  return (
    <div>
      <Helmet>
        <title>Bienvenidos!</title>
      </Helmet>
      <Slides />
      <div className='wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-9'>
              <h1 className='title'>Novedades</h1>
              {loading ? (
                'cargando...'
              ) : (
                <>
                  <div className='news'>
                    {posts.map((post) => (
                      <NewCard key={post.id} post={post} />
                    ))}
                  </div>
                  <Link to='/novedades' className='btn btn-secondary'>
                    Ver mas novedades
                  </Link>
                </>
              )}
            </div>
            <div className='col-md-3'>
              <Aside />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
