import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/static/logo-white.svg';

export default function Navbar() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const handleLink = () => setIsNavCollapsed(true);

  return (
    <nav className='navbar navbar-expand-lg navbar-dark'>
      <div className='container'>
        <Link onClick={handleLink} className='navbar-brand ml-auto' to='/'>
          <img src={Logo} alt='' />
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded={!isNavCollapsed ? true : false}
          aria-label='Toggle navigation'
          onClick={handleNavCollapse}
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div
          id='navbarSupportedContent'
          className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`}
        >
          <ul className='navbar-nav ms-auto'>
            {/* <li className='nav-item'>
              <Link onClick={handleLink} className='nav-link' to='/novedades'>
                Novedades
              </Link>
            </li> */}
            <li className='nav-item'>
              <Link
                onClick={handleLink}
                className='nav-link'
                to='/quienes-somos'
              >
                Quienes Somos
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                onClick={handleLink}
                className='nav-link'
                to='/archivo-municipal-de-la-memoria'
              >
                Archivo M. de la Memoria
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                onClick={handleLink}
                className='nav-link'
                to='/semana-de-la-memoria'
              >
                Semana de la Memoria
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                onClick={handleLink}
                className='nav-link'
                to='/causa-gutierrez'
              >
                Causa Gutiérrez
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                onClick={handleLink}
                className='nav-link'
                to='/biblioteca-jorge-harriague'
              >
                Biblioteca J. Harriague
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                onClick={handleLink}
                className='nav-link'
                to='/memoria-y-educacion'
              >
                Memoria y Educación
              </Link>
            </li>
            {/* <li className='nav-item'>
              <a
                className='nav-link'
                href='https://contenidos.gobiernoriocuarto.gob.ar/'
                target='_blank'
                rel='noopener noreferrer'
              >
                admin
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}
