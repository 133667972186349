import React from "react";

export default function Video(props) {
  const videos = props.videos;
  return (
    <div className="row">
      {videos.map((video) => (
        <React.Fragment key={video.id}>
          {(video.publico === "si") & (video.videoUrl !== "") ? (
            <div className="col-md-6">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="card-title">{video.title}</div>
                  <div className="ratio ratio-16x9">
                    <iframe
                      src={`https://www.youtube.com/embed/${video.videoUrl}?rel=0`}
                      title="YouTube video"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
