import ListOfPosts from '../components/ListOfPosts';

export default function News() {
  return (
    <div className='wrapper'>
      <div className='container'>
        <h1 className='title title-sm'>Noticias</h1>
        <ListOfPosts />
      </div>
    </div>
  );
}
