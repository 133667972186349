import React from 'react';
import { Link } from 'react-router-dom';
import FormatDate from '../utils/FormatDate';

export default function CardPost({ post }) {
  return (
    <Link className='card card-post' to={`novedades/${post.id}/${post.slug}`}>
      <div className='card-image'>
        {post.main_picture && <img src={post.main_picture.small} alt='' />}
      </div>
      <div className='card-body'>
        <small>
          <FormatDate date={post.publication_date} />
        </small>
        <h4 className='card-title'>{post.title}</h4>
        <p className='card-text'>{post.excerpt}</p>
      </div>
    </Link>
  );
}
