import React, { useState } from 'react';

export default function DetalleSemanaMemoria(props) {
  const [media] = useState(false);
  return (
    <>
      <img
        loading='lazy'
        className='cover'
        src='https://res.cloudinary.com/gobriocuarto/image/upload/v1615816223/CasaDeLaMemoria/Semana/cover_mpjmvk.webp'
        alt=''
      />
      <p>
        Se realiza cada año durante la semana del 24 de marzo en el marco del
        Día nacional por la Memoria, la Verdad y la Justicia. Está instituida
        por Ordenanza Municipal N° 758 desde el año 2018.
      </p>
      <p>
        Es producto de un trabajo mancomunado de los Organismos de Derechos
        Humanos, el Estado Municipal y Provincial y la Universidad Nacional Río
        Cuarto (UNRC). Las actividades recorren las escuelas en todos los
        niveles, los clubes sociales, las bibliotecas barriales y los más
        variados espacios culturales, educativos y sociales de nuestra ciudad.
      </p>
      <p>
        Se realizan en la semana: teatrales, musicales, fílmicas, deportivas,
        todas con la misma finalidad, la concientización de la sociedad en temas
        de Memoria, Verdad y Justicia, llevando hasta los más pequeños rincones
        palabras y acciones que promuevan el pensamiento crítico y la reflexión
        sobre lo que fueron los años y el terror de la dictadura cívico militar.
      </p>
      <article>
        <h4>1° Edición: 2017</h4>
        <div className='row'>
          <div className='col-md-6'>
            <img
              className='img-fluid'
              loading='lazy'
              src='https://res.cloudinary.com/gobriocuarto/image/upload/v1615816223/CasaDeLaMemoria/Semana/edicion-1_h3jzbq.webp'
              alt=''
            />
          </div>
        </div>
        <p>
          La primera edición de la Semana de la Memoria en nuestra Ciudad que
          fue organizada por el Gobierno Provincial y Municipal, el Concejo
          Deliberante y la UNRC y contó con el aval y acompañamiento de los
          organismos de Derechos Humanos que son parte de la Comisión Municipal
          de la Memoria se realizó desde el 20 al 24 de marzo.
        </p>
        <p>
          Participaron más de 5.000 personas. Las actividades fueron presentadas
          en conferencia de prensa en la Sala de Situaciones del Palacio
          Municipal por el Intendente Juan Manuel Llamosas acompañado del
          Presidente del Concejo Deliberante Darío Fuentes, el rector de la UNRC
          Roberto Rovere, el Director del Centro Cívico Hugo Palacios,
          referentes de organismos de DD.HH y funcionarios locales, provinciales
          y nacionales.{' '}
        </p>
        <p>
          Durante ese año hubo reconocimientos a ex presos políticos y
          desaparecidos que transitaron sus años de estudio primario o
          secundario en el Colegio Pías y la Escuela San Buenaventura; charlas y
          talleres destinados a alumnos de escuelas de la ciudad; actividades de
          reflexión en el Concejo Deliberante; muestras artísticas; eventos
          culturales y la apertura de una calle pública con el nombre de un
          desaparecido riocuartense.
        </p>
        {/* TODO */}
        {media && (
          <ul>
            <li>
              <a href='/'>ver fotos</a>
            </li>
            <li>
              <a href='/'>ver video</a>
            </li>
          </ul>
        )}
      </article>
      <article>
        <h4>2° Edición: 2018</h4>
        <div className='row'>
          <div className='col-md-6'>
            <img
              className='img-fluid'
              loading='lazy'
              src='https://res.cloudinary.com/gobriocuarto/image/upload/v1615816223/CasaDeLaMemoria/Semana/edicion-2_ydewwg.webp'
              alt=''
            />
          </div>
        </div>
        <p>
          La segunda edición se desarrolló entre el 19 y 27 de marzo. Tuvo una
          variada grilla de actividades: actos públicos, homenajes, charlas,
          reflexión y moviliación. Fue nuevamente organizada por el Gobierno
          Provincial y Municipal, el Concejo Deliberante y la UNRC y contó con
          el aval y acompañamiento de los organismos de Derechos Humanos.
        </p>
        <p>
          Participaron más de 5.000 personas. En el acto de apertura, el
          Municipio anunció la presentación como querellante de la Comisión
          Municipal de la Memoria en la Causa Guiérrez que investiga delitos de
          lesa humanidad cometidos en Río Cuarto y el sur de Córdoba durante la
          última dicadura cívico-militar.
        </p>
        <p>
          Además, como hecho destacado se desarrolló la declaración de Ciudadana
          Ilustre para Antonia Cisneros, madre de Plaza de Mayo y madre del
          desaparecido Ignacio “Corcho” Cisneros.
        </p>
        <p>
          También se desarrollaron acitividades tales como: el cicl Cine y
          Memoria; el descubrimiento de una placa conmemorativa en la Escuela
          Vélez Sarsfield en homenaje a Juan Carlos Perchante (desaparecido) y
          de otra en el Centro Educativo Bartolomé Mitre que homenajea a Alfredo
          “Peco” Duarte (Desaparecido); se realizó la proyección del documental
          “La Sentencia: Crónica de un Día de Justicia” en el Coloso de Banda
          Norte; la inauguración del Jardín Seco por la Memoria en el Centro de
          Salud Municipal; entre otras.
        </p>
        {/* TODO */}
        {media && (
          <ul>
            <li>
              <a href='/'>ver fotos</a>
            </li>
            <li>
              <a href='/'>ver video</a>
            </li>
          </ul>
        )}
      </article>
      <article>
        <h4>3° Edición: 2019</h4>
        <div className='row'>
          <div className='col-md-6'>
            <img
              className='img-fluid'
              loading='lazy'
              src='https://res.cloudinary.com/gobriocuarto/image/upload/v1615816222/CasaDeLaMemoria/Semana/edicion-3_pipqin.webp'
              alt=''
            />
          </div>
        </div>
        <p>
          La tercera edición tuvo lugar entre el 17 y 24 de marzo. Fueron xx
          actividades, participaron más de 5.000 personas en xx actividades. La
          apertura fue en el Salón Blanco de la Municipalidad y estuvo
          encabezada por el Intendente Municipal Juan Manuel Llamosas y
          autoridades del Gobierno de la Provincia, la UNRC y el Concejo
          Deliberante. También tuvieron un lugar destacado los organismos de
          DD.HH, sus relatos, sus historias de vida y las reflexiones planteadas
          en torno a los avances de la Causa Gutiérrez que invesiga delitos de
          lesa humanidad cometidos en el sur de Córdoba y en la cual la Comisión
          de la Memoria es querellante.
        </p>
        <p>
          Entre las actividades más destacadas, además, estuvieron: la charla
          “Los medios y la dictadura” a cargo de periodistas de la ciudad; cine
          en los barrios con proyeccción de documentales sobre desaparecidos; el
          desarrollo de la campaña “Clubes con memoria” que contempló
          intervención de los planteles de fútbol de la ciudad; la señalización
          de Sitios de Memoria en la ciudad tales como el domicilio de Gabriel
          Braunstein Maiden y el Ex Buen Pastor; entre otras.
        </p>
        <p>
          Pero sin dudas el evento de la Semana se desarrolló el día Jueves
          21/03 con la Puesta en valor de la Casa de la Memoria. El acto contó
          con la presencia del Intendente Municipal Juan Manuel Llamosas,
          funcionarios, militantes de derechos humanos y docentes y alumnos de
          escuelas de la ciudad. En el Sitio de la Memoria, para la ocasión se
          realizaron obras de remodelación en techos, paredes y baños e
          incorporación de tecnología para el desarrollo de las actividades.
        </p>
        {/* TODO */}
        {media && (
          <ul>
            <li>
              <a href='/'>ver fotos</a>
            </li>
            <li>
              <a href='/'>ver video</a>
            </li>
          </ul>
        )}
      </article>
    </>
  );
}
