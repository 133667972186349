import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Aside from '../template/Aside';
import { FaFileDownload } from 'react-icons/fa';
import Gallery from '../components/Gallery';
import { getImages } from '../services/getImages';
import Loading from '../components/Loading';
import DetalleQuienesSomos from '../components/DetalleQuienesSomos';

export default function QuienesSomos() {
  const title = 'Casa de la Memoria';
  const sheet = 'QuienesSomos';
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(function () {
    setIsLoading(true);
    getImages(sheet).then((images) => {
      setPhotos(images);
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <h1 className='title'>{title}</h1>
              <div className='detail'>
                <DetalleQuienesSomos />
              </div>
            </div>
            <div className='col-md-6 col-lg-3'>
              <div className='resources sticky-top'>
                <div className='gallery'>
                  <h4>Fotos</h4>
                  {isLoading ? <Loading /> : <Gallery images={photos} />}
                </div>
                <div className='download'>
                  <h4>Anexos</h4>
                  <a
                    href='https://drive.google.com/file/d/1vbSXGQpawoCH1dA0s3YxhH2d58D_xxFq/view?usp=sharing'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FaFileDownload /> Ordenanza 188/08 y modificatorias
                    vigentes
                  </a>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-3'>
              <Aside />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
