import React from 'react';

export default function DetalleCausaGutierrez(props) {
  return (
    <>
      <p>
        El Municipio de la ciudad de Río Cuarto, a través de la Comisión
        Municipal de la Memoria, es querellante en la causa judicial “Gutiérrez
        y otros” que reúne la mayoría de los delitos de lesa humanidad cometidos
        en la ciudad de Río Cuarto y su zona de influencia, en lo que constituye
        sin duda la “megacausa” del sur de la provincia de Córdoba.{' '}
      </p>
      <p>
        La causa se instruye en el Juzgado Federal Nº 1 de Córdoba, FCB. 2880/14
        y está caratulada como "Gutiérrez Hermes Vicente y otros, privación
        ilegal de la libertad/ Art. 144 bis, inc. 1, imposición de torturas
        (Art. 144 inc. 1), Abuso Deshonesto, Mod. Ley 25087 (Sustituido conf.
        art. 23 Ley 26.842 y violación según párrafo 4to. (Art. 119 inc. c,
        víctima Levi, Alberto y otros".{' '}
      </p>
      <p>
        La Comisión Municipal de la Memoria de Río Cuarto, en base a una
        decisión política del Poder Ejecutivo Municipal, se constituyó en parte
        querellante mediante un escrito presentado el 15/02/2019 que, luego de
        una serie de dilaciones judiciales (recusación del Juez Vaca Narvaja y
        su reemplazo por el juez Bustos Fierro) se admitió recién el 16/05/2019.
      </p>

      <h4>La Causa</h4>
      <p>
        La causa Gutiérrez consta de 14 tomos -digitalizados y en poder de la
        querella- y se investigan los siguientes delitos: a) Privación ilegítima
        de la libertad agravada, b) imposición de tormentos, c) abuso deshonesto
        agravado y d) violación agravada. Los delitos descriptos en la acusación
        no impiden que la querella pueda plantear nuevos hechos,
        fundamentalmente del accionar de la Triple A entres los años 1973 y
        marzo de 1976, consistentes en atentados con explosivos, secuestros,
        torturas y desaparición de personas. Se presume que a diferencia de lo
        ocurrido en Córdoba, en Río Cuarto y el sur de la provincia estos grupos
        respondían al ex ministro de Bienestar Social de la Nación José López
        Rega.
      </p>
      <p>
        El fiscal de la causa es Facundo Trota, aunque podría volver a ocupar
        ese lugar la fiscal Graciela López de Filoñuk, cuya permanencia en el
        cargo también es objeto de disputa judicial en los Tribunales Federales
        de Córdoba.{' '}
      </p>
      <p>
        La “causa Gutiérrez” reúne 73 imputados -sin perjuicio de poder agregar
        a los responsables de la Triple A por su accionar delictivo, germen del
        terrorismo de Estado-y 59 víctimas, mencionados en el Requerimiento de
        Instrucción fechado en noviembre de 2015 y suscripto por la entonces
        fiscal López de Filoñuk. Allí están desarrollados la mayoría de los
        hechos delictivos de lesa humanidad sufridos por cada una de las
        víctimas y los imputados que participaron en los mismos. Es un cuerpo de
        208 folios en formato A8.{' '}
      </p>
      <p>
        Los abogados que representan a la Municipalidad de Río Cuarto como parte
        querellante en el proceso son los abogados Daniel Olartecoechea, DNI:
        8.578.346, MF.T. 505-F310 y Gonzalo Otero Pizarro, MF. T. 65-F27, DNI:
        5.071.213. Olartecoechea preside actualmente la Asociación de Ex Presos
        Políticos de Río Cuarto y el Sur de la Provincia de Córdoba. Estuvo
        detenido ilegalmente en el Departamento de Informaciones de la Policía
        de Córdoba (D2) y emprendió el camino del exilio en 1975, al igual que
        su colega Otero Pizarro. Ambos sufrieron amenazas y atentados con
        explosivos en sus viviendas por parte de la Triple A.
      </p>
      <p>
        Los letrados de la Comisión de la Memoria estiman que, a pesar de las
        dilaciones judiciales y los efectos de la pandemia del Coronavirus,
        sería posible elevar la causa a juicio a finales del corriente año 2021
        o inicios del próximo 2022. Problabemente tal hecho se constituirá en
        histórico no solo para Río Cuarto y la región sino también para toda la
        provincia de Córdoba.
      </p>
      <p className='lead'>
        ¡Si tenés datos, información o testimonios para aportar a la Causa
        Gutiérrez, no dudes en contactarnos!
      </p>
    </>
  );
}
