import React from 'react';
import { SRLWrapper } from 'simple-react-lightbox';

export default function DemoGallery({ title, images }) {
  // const images = props.gallery;
  //console.log(images);
  return (
    <SRLWrapper className='gallery'>
      {images.map((image, index) => (
        <React.Fragment key={index}>
          <a href={image.large} className='gallery-image'>
            <img loading='lazy' src={image.large} alt={title} />
          </a>
        </React.Fragment>
      ))}
    </SRLWrapper>
  );
}
