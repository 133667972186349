import React from 'react';

export default function DetalleBiblioteca(props) {
  return (
    <>
      <p>
        La Bilbioteca Jorge Harriague funciona en la sede de la Casa de la
        Memoria. Su nombre hace referencia a un militante popular oriundo de
        nuestra ciudad, desaparecido durante la última dictadura.
      </p>
      <p>
        Contiene 1319 libros relacionados principalmente con la política, la
        historia, la filosofía, la literatura, y los derechos humanos.
      </p>
      <p>
        El proyecto de biblioteca nació en el año 2004, con la donación de un
        conjunto de libros recuperados de un calabozo del ex centro clandestino
        de detención conocido como D-2 Informaciones, que funcionó en la policía
        de la provincia de Mendoza. Luego, fue creciendo con la obtención de
        material específico de derechos humanos, provisto por diferentes
        instituciones y organismos que trabajan la temática (C.E.L.S.,
        Asociación Madres de Plaza de Mayo, Abuelas de Plaza de Mayo, Co.Na.Di,
        Co.Na.Dep, Cispren, H.I.J.O.S y Familiares de detenidos y desaparecidos
        por razones políticas entre otros).
      </p>
      <p>
        Una importante donación fue por parte de la familia de Américo Di Cola,
        un reconocido militante local.
      </p>
      <p>
        Su inauguración se realizó el 7 de octubre 2006, en el marco de la
        Segunda Feria del Libro Juan Filloy.
      </p>
      <p>Acercarte a consultar material</p>

      <h4>Contacto</h4>
      <p>
        Horario de atención: Lunes a viernes de 9.30 a 12.30 horas. <br />
        Teléfono: 4671263
      </p>
    </>
  );
}
