import useSinglePost from '../hooks/useSinglePost';
import { useParams, Redirect } from 'react-router';
import { Helmet } from 'react-helmet';
import Aside from '../template/Aside';
// import data from '../data/posts.json';
import Gallery from '../components/Gallery';
import FormatDate from '../utils/FormatDate';

export default function Post() {
  const { id } = useParams();
  const { post, loading, isError } = useSinglePost({ id });
  //console.log(post);

  if (loading)
    return (
      <div className='wrapper'>
        <div className='container'>cargando ...</div>
      </div>
    );

  if (isError) return <Redirect to='/404' />;

  if (!post) return null;

  return (
    <>
      <Helmet>
        <title>{post.title}</title>
      </Helmet>
      <div className='wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <h1 className='title title-sm'>{post.title}</h1>
              <div className='detail'>
                {post.publication_date ? (
                  <div className='date'>
                    <FormatDate date={post.publication_date} />
                  </div>
                ) : (
                  ''
                )}
                <p className='lead'>{post.excerpt}</p>
                {post.media.main_picture ? (
                  <img
                    className='cover'
                    src={post.media.main_picture.large}
                    alt=''
                  />
                ) : (
                  ''
                )}
                <div dangerouslySetInnerHTML={{ __html: post.body }}></div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='resources sticky-top'>
                <div className='gallery'>
                  <h4>Fotos</h4>
                  <Gallery title={post.title} images={post.media.gallery} />
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <Aside />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
