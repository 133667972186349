const apiURL = `https://script.google.com/macros/s/AKfycbwI68_FYAYzOLKHwbxIJSX1RH_21MBF1nrxXOpHZlNsKkqvPRHh/exec?spreadsheetId=16bmlNR9K0aubtBDYZxsYCGnFk6DDj7YjU-Vj3Lkkiuc&sheet=`;

export function getImages(props) {
  const sheet = props;
  //console.log(props);
  return fetch(apiURL + sheet)
    .then((resp) => resp.json())
    .then((response) => {
      const data = response;
      //console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
}
