import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import Layout from '../template/Layout';
import Home from '../pages/Home';
import QuienesSomos from '../pages/QuienesSomos';
import ArchivoMemoria from '../pages/ArchivoMemoria';
import SemanaMemoria from '../pages/SemanaMemoria';
import CausaGutierrez from '../pages/CausaGutierrez';
import Biblioteca from '../pages/Biblioteca';
import MemoriaEducacion from '../pages/MemoriaEducacion';
import Posts from '../pages/Posts';
import Post from '../pages/Post';
import Multimedia from '../pages/Multimedia';
import Escuelas from '../pages/Escuelas';
import Desaparecidos from '../pages/Desaparecidos';
import Admin from '../pages/Admin';
import NotFound from '../pages/NotFound';

import ScrollToTop from '../utils/ScrollToTop';

// export function Admin() {
//   return (window.location = 'https://contenidos.gobiernoriocuarto.gob.ar/');
// }

export default function App() {
  return (
    <Router>
      <ScrollToTop />
      <SimpleReactLightbox>
        <Layout>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/quienes-somos' component={QuienesSomos} />
            <Route
              exact
              path='/archivo-municipal-de-la-memoria'
              component={ArchivoMemoria}
            />
            <Route
              exact
              path='/semana-de-la-memoria'
              component={SemanaMemoria}
            />
            <Route
              exact
              path='/biblioteca-jorge-harriague'
              component={Biblioteca}
            />
            <Route
              exact
              path='/memoria-y-educacion'
              component={MemoriaEducacion}
            />
            <Route exact path='/causa-gutierrez' component={CausaGutierrez} />
            <Route exact path='/novedades' component={Posts} />
            <Route exact path='/novedades/:id/:slug' component={Post} />
            <Route exact path='/multimedia' component={Multimedia} />
            <Route exact path='/escuelas' component={Escuelas} />
            <Route
              exact
              path='/desaparecidos-rio-cuarto'
              component={Desaparecidos}
            />
            <Route exact path='/admin' component={Admin} />
            <Route path='*' component={NotFound} />
          </Switch>
        </Layout>
      </SimpleReactLightbox>
    </Router>
  );
}
