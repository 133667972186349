import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Aside from '../template/Aside';
import Video from '../components/Video';
import { getVideos } from '../services/getVideos';
import Loading from '../components/Loading';

export default function Multimedia() {
  const title = 'Multimedia';
  const sheet = 'Multimedia';
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(function () {
    setIsLoading(true);
    getVideos(sheet).then((images) => {
      setVideos(images);
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-9'>
              <h1 className='title'>{title}</h1>
              <div className='detail'>
                <div className='video'>
                  {isLoading ? <Loading /> : <Video videos={videos} />}
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <Aside />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
