import React from 'react';

export default function DetalleArchivoMemoria(props) {
  return (
    <>
      <p>
        El Archivo Municipal de la Memoria (AMM) est&aacute; conformado por
        material documental, bibliogr&aacute;fico, y audiovisual con contenidos
        sobre la memoria local, regional y nacional de nuestro pasado reciente.
        Asimismo, cuenta con materiales pedag&oacute;gicos sobre derechos
        humanos y materias pertinentes.&nbsp;
      </p>
      <p>
        Su funci&oacute;n principal es brindar informaci&oacute;n al
        p&uacute;blico en general sobre tem&aacute;ticas relacionadas a los
        Derechos Humanos, el Pasado Reciente, Terrorismo de Estado y procesos de
        memoria e identidad.
      </p>
      <p>
        Est&aacute; a cargo de una Coordinadora elegida por la Comisi&oacute;n
        Municipal de la Memoria y designada por Decreto del Intendente
        Municipal. Actualmente, la responsable es la ex presa pol&iacute;tica y
        archiv&oacute;loga Mercedes Brusa.
      </p>
      <h4>Fondos documentales</h4>
      <p>
        El AMM contiene materiales muy heterog&eacute;neos. La mayor&iacute;a de
        ellos constituyen donaciones de diversa procedencia que se han reunidos
        en torno a la problem&aacute;tica de los derechos humanos.
      </p>
      <p>
        Entre esos materiales pueden mencionarse repositorios procedentes de:
      </p>
      <ul>
        <li>
          Organismos de Derechos Humanos (C.E.L.S, Madres y Abuelas de Plaza de
          Mayo, Co.NA.DI, Conadep, H.I.J.O.S, Expresos y Familiares de
          detenidos-desaparecidos por razones pol&iacute;ticas),
        </li>
        <li>
          Instituciones gremiales locales (C&iacute;rculo Sindical de la Prensa,
          Cispren-R&iacute;o Cuarto),
        </li>
        <li>
          Colecciones particulares (como las de Am&eacute;rico Di Cola, vecino y
          militante local)
        </li>
        <li>
          Un conjunto de libros recuperados de un calabozo del CCDyT, D2 de la
          polic&iacute;a de la Provincia de Mendoza.
        </li>
      </ul>
      <p>
        Sus fondos documentales permiten estudiar centralmente los procesos de
        movilizaci&oacute;n de la sociedad civil iniciados en la d&eacute;cada
        de 1960, el advenimiento del terrorismo de Estado, la
        recuperaci&oacute;n democr&aacute;tica, los primeros juicios por causas
        de lesa humanidad y el proceso de Memoria, Verdad y Justicia
        desarrollado hasta la actualidad en la ciudad, la regi&oacute;n y el
        territorio nacional.
      </p>
      <p>
        El material tambi&eacute;n cuenta con una vasta hemeroteca, formada por
        m&aacute;s de 30 colecciones de revistas (locales, nacionales y
        latinoamericanas) de las d&eacute;cadas de los 50&rsquo;, 60&rsquo;,
        70&rsquo;, 80&rsquo;, 90&rsquo; hasta la actualidad; material
        period&iacute;stico de la misma &eacute;poca, entre ellos colecciones de
        peri&oacute;dicos como La Opini&oacute;n, La Calle, Puente, Marcha,
        entre otros.
      </p>
      <h4>Objetivos</h4>
      <ul>
        <li>
          Colaborar con la preservaci&oacute;n de la historia reciente de
          nuestra ciudad como legado para las pr&oacute;ximas generaciones
        </li>
        <li>
          Proporcionar las herramientas para&nbsp; el estudio y la
          investigaci&oacute;n, no s&oacute;lo en la b&uacute;squeda de la
          verdad sino adem&aacute;s como camino hacia la reconstrucci&oacute;n
          del tejido social ante las violaciones de los derechos humanos.&nbsp;
        </li>
        <li>
          Promover mediante acciones concretas, talleres, charlas informativas y
          debates, la difusi&oacute;n de su acervo documental, como posibilidad
          cierta de lucha contra la impunidad y por la plena vigencia de los
          derechos humanos.&nbsp;
        </li>
        <li>
          Poner a resguardo la informaci&oacute;n, testimonios y
          documentaci&oacute;n en general que sea necesaria para el estudio del
          desarrollo del plan sistem&aacute;tico de terrorismo de estado en la
          ciudad de R&iacute;o Cuarto y regi&oacute;n.
        </li>
        <li>
          Proceder con la sistematizaci&oacute;n de m&eacute;todos que promuevan
          la digitalizaci&oacute;n de la documentaci&oacute;n de tal manera que
          puedan ser consultados sin que se incurra en la p&eacute;rdida parcial
          o total de ellos.&nbsp;
        </li>
        <li>
          Crear una base de datos con el objetivo de analizar, clasificar y
          ordenar la informaci&oacute;n, testimonios y documentos del acervo.
        </li>
      </ul>

      <h4>Entrevista</h4>
      <p>
        de Uni R&iacute;o TV a la Dra. en Historia Daniela Wagner, coordinadora
        de la Pr&aacute;ctica Socio Comunitaria
      </p>
      <div className='ratio ratio-16x9 mb-4'>
        <iframe
          src={`https://www.youtube.com/embed/0tZeYON8a2U?rel=0`}
          title='YouTube video'
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
}
