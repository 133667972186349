import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Aside from '../template/Aside';
import Gallery from '../components/Gallery';
import { getImages } from '../services/getImages';
import Loading from '../components/Loading';
import DetalleCausaGutierrez from '../components/DetalleCausaGutierrez';
import ComplentosCausaGutierrez from '../components/ComplentosCausaGutierrez';

export default function CausaGutierrez() {
  const title = 'Causa Gutierrez';
  const sheet = 'CausaGutierrez';
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(function () {
    setIsLoading(true);
    getImages(sheet).then((images) => {
      setPhotos(images);
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <h1 className='title'>{title}</h1>
              <div className='detail'>
                <DetalleCausaGutierrez />
                <ComplentosCausaGutierrez />
              </div>
            </div>
            <div className='col-md-6 col-lg-3'>
              <div className='resources sticky-top'>
                <div className='gallery'>
                  <h4>Fotos</h4>
                  {isLoading ? <Loading /> : <Gallery images={photos} />}
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-3'>
              <Aside />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
