import React from 'react';
import slugify from 'slugify';
import { Link } from 'react-router-dom';
import FormatDate from '../utils/FormatDate';

export default function NewCard(props) {
  const { post } = props;
  const url = slugify(post.title.toLowerCase());

  return (
    <>
      <Link to={`/novedades/${post.id}/${url}`}>
        <div className='card'>
          <div className='row'>
            <div className='col-md-4'>
              <img
                className='card-image'
                src={post.main_picture.small}
                alt=''
              />
            </div>
            <div className='col-md-8'>
              <div className='card-body'>
                <p className='card-date mb-1'>
                  <FormatDate date={post.publication_date} />
                </p>
                <h4 className='card-title'>{post.title}</h4>
                <p className='card-text'>{post.excerpt}</p>
                <div className='btn btn-link p-0'>Leer más</div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}
