import React from 'react';
/*eslint-disable no-unused-vars*/
import { Collapse } from 'bootstrap';

export default function ComplentosCausaGutierrez() {
  return (
    <div className='complements'>
      <h4>Información Complementaria</h4>
      <h5>
        <a
          className=''
          data-bs-toggle='collapse'
          href='#antecedentes'
          role='button'
          aria-expanded='false'
          aria-controls='antecedentes'
        >
          Antecedentes
        </a>
      </h5>
      <div className='collapse multi-collapse mb-3' id='antecedentes'>
        <div className='card card-body'>
          <p>
            En el año 2009, el Estado municipal ya se había presentado como
            querellante en la causa que investiga la desaparición de Gabriel
            Braunstein Maiden. El hecho se constituyó como inédito ya que Río
            Cuarto fue el primer lugar del país dónde una Comisión de la Memoria
            de carácter municipal tomó la iniciativa de avanzar en una
            presentación judicial de estas características.
          </p>
          <p>
            En la presentación, el Estado municipal a través de la Comisión
            presentó un extenso informe a la fiscalía de la Dra. Graciela López
            de Filoñuk quien de oficio comenzó una investigación que abordó
            nuevas líneas en relación a los hechos sucedidos en la región
            durante el terrorismo de estado en lo correspondiente a la Zona
            militar 3115.
          </p>
          <p>
            Esta investigación dio lugar a otra causa que nuclea a víctimas del
            sur de la provincia de Córdoba y conformó la hoy denominada causa
            “Gutiérrez.
          </p>
        </div>
      </div>
      <h5>
        <a
          className=''
          data-bs-toggle='collapse'
          href='#hermesGutierrez'
          role='button'
          aria-expanded='false'
          aria-controls='hermesGutierrez'
        >
          Hermes Gutiérrez
        </a>
      </h5>
      <div className='collapse multi-collapse mb-3' id='hermesGutierrez'>
        <div className='card card-body'>
          <p>
            A partir del trabajo que viene realizando la Comisión de la Memoria
            en la querella de la Causa se ha podido establecer que uno de los
            máximos responsables del accionar ilegal de la represión fue el ex
            teniente coronel Hermes Vicente Gutiérrez. Se desempeñó como Jefe de
            distrito Militar Río Cuarto hasta fines de 1976, entre sus funciones
            estaba la de dirigir una brigada antiguerrilera, la misma funcionaba
            en dependencias de la actual Unidad Departamental Nº 9, o Jefatura
            de la Policía Provincial. El aparato represivo estaba compuesto por
            personal de la Policía Federal y de la Policía Provincial que
            operaban bajo órdenes directas de Hermes Gutiérrez, el mismo se
            encargaba de los secuestros, detenciones, allanamientos en los
            departamentos de Río Cuarto, Juárez Celman, Roque Sáenz y General
            Roca.
          </p>
          <p>
            De acuerdo a los Boletines Reservados del Ejército a los que han
            tenido acceso las organizaciones, el 15 de diciembre 1976 Gutiérrez
            fue reemplazado en su cargo por el teniente coronel D. Juan Guido
            Olguín, mientras que él fue enviado como responsable del distrito
            militar de San Juan. A partir de fines de 1977, el jefe de distrito
            militar Río Cuarto pasó a ser el teniente coronel José Camilo Gay.
          </p>
          <p>
            Además, por decreto del presidente de facto Jorge Rafael Videla, el
            206/1980 del 28 de enero de 1980, se nombró a Gutiérrez como “vocal
            del Consejo de Guerra Permanente para el Personal Subalterno del
            Ejército ‘Córdoba’”, por un período que concluiría el 15 de
            septiembre de 1980.
          </p>
        </div>
      </div>
    </div>
  );
}
