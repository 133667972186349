import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function DetalleMemoriaEducacion(props) {
  const [media] = useState(false);
  return (
    <>
      <p>
        El área de Memoria y Educación de la Casa de la Memoria tiene como
        función principal el desarrollo, construcción y transmisión de saberes y
        prácticas asociadas a las luchas por la memoria, la verdad y la justicia
        y cuyo objeto central es generar una conciencia social sobre lo que
        significó el terrorismo de Estado en Río Cuarto y el País.
      </p>
      <p>
        Desde el Área, se promueve el ejercicio colectivo de la reflexión, no
        sólo sobre ese pasado reciente sino sobre las tramas del presente que se
        vinculan con los procesos de construcción cotidiana de la democracia. De
        tal modo, se concibe a la educación en derechos humanos como una
        mediación importante para la afirmación y la construcción de la misma.
      </p>
      <p>
        En esa perspectiva, se generan talleres, charlas, muestras, proyecciones
        y demás dispositivos que sirven como vehículo de transmisión y que se
        abordan desde diferentes campos: el arte, la cultura, la comunicación,
        etc. Así desde múltiples formatos y lenguajes se aborda la lucha por los
        Derechos Humanos desde diferentes aristas teniendo como núcleo central
        la figura de los desaparecidos.
      </p>
      <h4>propuestas pedagógicas</h4>
      <p>
        Accedé a <Link to='/escuelas'>nuestras propuestas pedagógicas</Link>{' '}
        para trabajar con niños, adolescentes y adultos
      </p>
      <p></p>
      {/* TODO */}
      {media && (
        <>
          <h4>Galerías de Fotos</h4>
          <ul>
            <li>
              <a href='/'>ver fotos</a>
            </li>
            <li>
              <a href='/'>ver video</a>
            </li>
          </ul>
        </>
      )}
    </>
  );
}
