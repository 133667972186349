export async function getSinglePost({ id } = {}) {
  const apiURL = `https://contenidos.gobiernoriocuarto.gob.ar/api/v1/posts/${id}`;

  return await fetch(apiURL, {
    headers: {
      'Portal-Id': 2
    }
  })
    .then((res) => res.json())
    .then((response) => {
      // if (response.error_message) {
      //   console.log(404);
      // }
      return response.data;
    });
}
