import { usePosts } from "../hooks/usePosts";
import CardPost from "./CardPost";

export default function ListOfPosts() {
  const { loading, loadingNextPage, posts, setPage } = usePosts({ limit: 9 });

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  if (loading) {
    return "Loading posts...";
  }

  return (
    <div className="row">
      {posts.map((post) => (
        <div className="col-md-4" key={post.id}>
          <CardPost post={post} />
        </div>
      ))}
      <div className="mb-3">
        {loadingNextPage ? (
          "cargando mas posts"
        ) : (
          <div>
            <button className="btn btn-secondary" onClick={handleNextPage}>
              cargar más
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
