import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
export default function Admin() {
  useEffect(() => {
    window.open('https://contenidos.gobiernoriocuarto.gob.ar/', '_blank');
    window.location = '/';
  }, []);
  return (
    <div>
      <Helmet>
        <title>Admin!</title>
      </Helmet>
      <div className='wrapper'>
        <div className='container py-5 text-center'>
          <h3>Te estamos rediregiendo al administrador ...</h3>
        </div>
      </div>
    </div>
  );
}
