import React from 'react';
/*eslint-disable no-unused-vars*/
import { Collapse } from 'bootstrap';

export default function ComplementosArchivoMemoria() {
  return (
    <div className='complements'>
      <h4>Información Complementaria</h4>
      <h5>
        <a
          className=''
          data-bs-toggle='collapse'
          href='#aporteHistoriadoresUNRC'
          role='button'
          aria-expanded='false'
          aria-controls='aporteHistoriadoresUNRC'
        >
          Aporte de historiadores de la UNRC
        </a>
      </h5>
      <div
        className='collapse multi-collapse mb-3'
        id='aporteHistoriadoresUNRC'
      >
        <div className='card card-body'>
          <p>
            Desde el a&ntilde;o 2019, la Universidad Nacional de R&iacute;o
            Cuarto (UNRC) a trav&eacute;s de su programa de Pr&aacute;cticas
            Socio Comunitarias (PSC) desarrolla emprende un trabajo fundamental
            para el mejoramiento del Archivo de la Memoria.
          </p>
          <p>
            Puntualmente a partir de la iniciativa de un grupo de docentes,
            graduados y alumnos de la Carrera de Historia perteneciente a la
            Facultad de Ciencias Humanas ejecutan un proyecto denominado
            &ldquo;Memorias, Educaci&oacute;n y Derechos Humanos:
            organizaci&oacute;n y puesta en valor de los fondos documentales de
            la Casa de la Memoria&rdquo; (Res. Rec. N&deg; 261/19; Providencia
            resolutiva 017/19 y Res. Rec. N&deg; 393/19; Resol. Rec. N&deg;
            358/2020).
          </p>
          <p>
            La propuesta es coordinada por la Dra. en Historia, Daniela Wagner y
            consiste en la colaboraci&oacute;n e intervenci&oacute;n de
            docentes, estudiantes, becarios del Profesorado y la Licenciatura en
            Historia, en la sistematizaci&oacute;n y catalogaci&oacute;n de la
            documentaci&oacute;n existente en el Archivo de la Memoria.
          </p>
          <p>
            Dicha sistematizaci&oacute;n tiene como finalidad organizar un
            repositorio documental para que pueda ser habilitado, tanto para
            consulta p&uacute;blica, como para investigadores y estudiantes de
            ciencias sociales, interesados en las huellas que el terrorismo de
            Estado &ndash;particularmente entre 1976-1983- ha dejado en la
            ciudad y regi&oacute;n sur de C&oacute;rdoba; en cuestiones
            vinculadas con la memoria y la educaci&oacute;n en derechos humanos.
          </p>
          <p>
            Como se trata de un Archivo muy heterog&eacute;neo por la
            documentaci&oacute;n que conserva, en todos los casos se han
            respetado la procedencia e integralidad de las colecciones, es
            decir, se mantiene el criterio de orden establecido por sus
            productores y/o tenedores de los materiales. Adem&aacute;s en el
            ordenamiento y catalogaci&oacute;n incluimos&nbsp; una
            contextualizaci&oacute;n de cada repositorio a los fines de ofrecer
            a los futuros consultantes informaci&oacute;n respecto a:
            procedencia, condiciones de producci&oacute;n, autores, grupos
            editoriales, colaboradores, l&iacute;neas editoriales (para los
            materiales de hemeroteca), destinatarios, soportes, entre otros
            aspectos.&nbsp;
          </p>
          <p>
            Para contactar a la docente Daniela Wagner pod&eacute;s escribirle a
            dwagner@hum.unrc.edu.ar; dwagner@arnet.com.ar
          </p>
        </div>
      </div>
      <h5>
        <a
          className=''
          data-bs-toggle='collapse'
          href='#avancesDesdeMayo2019'
          role='button'
          aria-expanded='false'
          aria-controls='avancesDesdeMayo2019'
        >
          Desde mayo de 2019 hasta hoy hemos avanzado en:
        </a>
      </h5>
      <div className='collapse multi-collapse mb-3' id='avancesDesdeMayo2019'>
        <div className='card card-body'>
          <ul>
            <li>
              Identificar, ordenar y clasificar los fondos del AMM (Fondos:
              Hemeroteca, Documentos, Audiovisuales y Bibliogr&aacute;ficos)
            </li>
            <li>
              Fichado, en soporte papel y digital, del Fondo Hemeroteca y sus
              colecciones.
            </li>
            <li>
              Elaboraci&oacute;n de un &iacute;ndice preliminar con los fondos
              del Archivo y sus respectivas colecciones que pod&eacute;s ver
              haciendo click aqu&iacute; (adjuntar PDF &ldquo;INDICE DE FONDOS
              AMM).&nbsp;
            </li>
            <li>
              Organizaci&oacute;n de cat&aacute;logos por cada colecci&oacute;n
              del Fondo Hemeroteca disponibles en el siguiente{' '}
              <a
                href='http://lineasdetiempo.unrc.edu.ar/linea.php?tl=8abbcvjnz9iy9p&amp;f=115&amp;lineasArray=(205)'
                target='_blank'
                rel='noopener noreferrer'
              >
                enlace
              </a>
            </li>
            <li>(En construcci&oacute;n)</li>
            <li>
              &middot; Ordenamiento f&iacute;sico de los Fondos del AMM, en la
              Sala de Guarda y SUM de la Casa de la Memoria.
            </li>
          </ul>
          <p>
            La consulta de los cat&aacute;logos puede realizarse en
            l&iacute;nea, pero el acceso a las piezas documentales es
            presencial, en la sede del AMM, en Casa de la Memoria.
          </p>
        </div>
      </div>
    </div>
  );
}
