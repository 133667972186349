import React from "react";
import { Helmet } from "react-helmet";
import Aside from "../template/Aside";
import DetalleEscuelas from "../components/DetalleEscuelas";

export default function Escuelas() {
  const title = "Material para Escuelas";

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <h1 className="title">{title}</h1>
              <div className="detail">
                <DetalleEscuelas />
              </div>
            </div>
            <div className="col-md-3">
              <Aside />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
