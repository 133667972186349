import React from 'react';

export default function DetalleQuienesSomos() {
  return (
    <>
      <p>
        La <b>CASA DE LA MEMORIA</b> es el único Sitio de la Memoria de Río
        Cuarto y el Sur de Córdoba y está ubicado en Bv. Ameghino y Pringles en
        el Predio del Andino, ciudad de Río Cuarto. Funciona como la sede
        oficial de la Comisión Municipal de la Memoria (instituida por Ord.
        Municipal 188/08 y modificatorias vigentes).
      </p>
      <p>
        Tiene como objetivo preservar la memoria y promover y defender los
        derechos humanos. Desde la misma, se promueven, organizan y realizan
        diversas actividades conmemorativas, culturales, educativas, sociales y
        políticas en conjunto con organismos de Derechos Humanos, organizaciones
        sociales y políticas, sindicatos, el Estado, agrupaciones estudiantiles
        y demás organizaciones intermedias.{' '}
      </p>
      <p>
        En su Sede, además, alberga un extenso y variado archivo documental y
        bibliográfico cuyo soporte se encuentra en diferentes formatos: libros,
        documentos, fotografías, videos, audios, mapas, láminas, manuscritos y
        otros.
      </p>
      <p>
        Su desarrollo se estructura en diferentes áreas de trabajo: 1) Archivo
        Municipal de la Memoria; 2) Pedagogía y Memoria; 3) Biblioteca Jorge
        Harriague; 4) Articulación-extensión; e 4) investigación, justicia y
        acompañamiento.
      </p>
      <h4>Comisión Municipal de la Memoria</h4>
      <p>
        La Comisión Municipal de la Memoria reúne a los organismos de Derechos
        Humanos de la Ciudad y las instituciones públicas vinculadas a la
        temática tanto del Municipio local como de la Universidad Nacional de
        Río Cuarto (UNRC).
      </p>
      <p>
        Entre sus distintas funciones (expresadas en la letra legislativa) tiene
        como tarea central la de “generar una conciencia social sobre lo que
        significó el terrorismo de Estado en Río Cuarto, la región y el país” y
        promover acciones para tal fin. Quizás la más importante es la que
        desarrolla desde hace algunos años en torno a la creación y
        mantenimiento del Archivo Municipal de la Memoria. Un acervo documental
        que no solo constituye una pieza clave en la configuración histórica del
        pasado reciente de la ciudad y, por ende, de la identidad riocuartense
        sino que resulta una herramienta fundamental, por ejemplo, para impulsar
        la apertura y sustanciación completa de las causas penales por hechos
        delictivos de lesa humanidad perpetrados en perjuicio de vecinos de Río
        Cuarto.{' '}
      </p>
      <p>Sus integrantes son:</p>
      <ul>
        <li>
          Asociación de Ex Presos Políticos de Río Cuarto y el Sur de Córdoba.
        </li>
        <li>
          Comisión de Familiares de detenidos y desaparecidos por razones
          políticas, Río Cuarto.
        </li>
        <li>Madres de Plaza de Mayo</li>
        <li>HIJOS</li>
        <li>Observatorio de Derechos Humanos de la UNRC</li>
        <li>Concejo Deliberante</li>
        <li>Subsecretaría de Derechos Humanos del Gobierno de Río Cuarto</li>
        <li>
          Personas individuales que sin encontrarse agrupados en una
          organización estén relacionados con la lucha permanente por los
          derechos humanos.
        </li>
      </ul>
    </>
  );
}
