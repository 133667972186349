import { useEffect, useState } from 'react';
import { getSinglePost } from '../services/getSinglePost';

export default function useSinglePost({ id }) {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [post, setPost] = useState(null);

  useEffect(
    function () {
      setLoading(true);
      getSinglePost({ id }).then((post) => {
        if (!post) {
          setIsError(true);
        }
        setPost(post);
        setLoading(false);
      });
    },
    [id]
  );
  return { loading, isError, post };
}
