import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Aside from "../template/Aside";
import { FaFileDownload } from "react-icons/fa";
import Gallery from "../components/Gallery";
import { getImages } from "../services/getImages";
import Loading from "../components/Loading";
import DetalleDesaparecidos from "../components/DetalleDesaparecidos";

export default function Desaparecidos() {
  const title = "Desaparecidos Río Cuarto";
  const sheet = "Desaparecidos";
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(function () {
    setIsLoading(true);
    getImages(sheet).then((images) => {
      setPhotos(images);
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <h1 className="title">{title}</h1>
              <div className="detail">
                <DetalleDesaparecidos />
                <div className="download">
                  <h4>Anexos</h4>
                  <a
                    href="https://drive.google.com/file/d/1vbSXGQpawoCH1dA0s3YxhH2d58D_xxFq/view?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFileDownload /> Ordenanza 188/08 y modificatorias
                    vigentes
                  </a>
                </div>
                <div className="gallery">
                  <h4>Fotos</h4>
                  {isLoading ? <Loading /> : <Gallery images={photos} />}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <Aside />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
